// import orange from '@material-ui/core/colors/orange';
// import indigo from '@material-ui/core/colors/indigo';
// import green from '@material-ui/core/colors/green';
// import brown from '@material-ui/core/colors/brown';
// import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
// import pink from '@material-ui/core/colors/pink';
import grey from '@material-ui/core/colors/grey';
// import purple from '@material-ui/core/colors/purple';
// import lime from '@material-ui/core/colors/lime';
import yellow from '@material-ui/core/colors/yellow';
// import blue from '@material-ui/core/colors/blue';
import cyan from '@material-ui/core/colors/cyan';
// import deepOrange from '@material-ui/core/colors/deepOrange';
import { arrayToObject, byId, exceedsVehicleLimits } from './utils';
// import { lighten } from '@material-ui/core/styles/colorManipulator';

// const colorsOld = [
// orange,
// indigo,
// green,
// brown,
// yellow,
// pink,
// blue,
// purple,
// lime,
// deepOrange,
// grey,
// cyan,
//   '#003366',
//   '#009999',
//   '#0001ff',
//   '#660066',
//   '#33cc33',
//   '#e69901',
//   '#666533',
//   '#8a2be2',
//   '#a45c67',
//   '#cc0199',
//   '#fe6600',
//   '#db5052',
// ];

const colors = [
  '#009999', // bad cyan
  '#660066', // bad purple
  // '#ffff00', // Yellow
  '#0000ff', // Blue
  '#cc0000', // Red
  '#33cc33', // green
  '#ff6600', // orange
  '#003366', // bad blue
  '#cc0099', // bad pink
  '#e69900', // dim orange
  '#666633', // bad yellow
  '#990033', // bad crimson
  // '#99ffcc', // dim green
  '#006600', // dark green
  '#9900cc', // purple
  '#663300', // bad orange
  '#42d4f4', // cyan/blue
  '#bfef45', // lime
  // '#e6beff', // lavender
  // '#bfbfbf', // gray (lighter)
  // 20 colors so far
  // '#fdfdfd',
  '#1d1d1d',
  '#ebce2b',
  '#702c8c',
  '#db6917',
  '#96cde6',
  '#ff8097', /// light pink
  '#c0bd7f',
  '#6f6e70', //slightly darker gray
  '#5fa641',
  '#d485b2',
  '#4277b6',
  '#df8461',
  '#463397',
  '#e1a11a',
  '#91218c',
  '#e8e948',
  '#7e1510',
  '#92ae31',
  '#6f340d',
  '#800024',
  '#455700',
];
// export const getColor = index => {
//   return (index || index === 0) && index >= 0
//     ? {
//         fillColor: colors[index % colors.length][400],
//         strokeColor: colors[index % colors.length][900],
//       }
//     : { fillColor: red[500], strokeColor: grey[900] };
// };
export const getColor = (index, retailer, vehicleTypes) => {
  return (index || index === 0) && index >= 0
    ? {
        fillColor: colors[index % colors.length],
        // strokeColor: colors[index % colors.length][900],
      }
    : {
        fillColor: retailer?.retry
          ? cyan[300]
          : exceedsVehicleLimits(retailer, vehicleTypes)
          ? yellow[300]
          : orange[500],
        strokeColor: grey[900],
      };
};

export const getRetailersFromPlan = (plan) => {
  const retailers = plan.inputs.reduce((acc, retailer, dmIndex) => {
    acc[retailer.id] = { ...retailer, dmIndex: dmIndex + 1, eta: 0 };
    return acc;
  }, {});
  plan.picklists.forEach(({ index, retailerIds }) => {
    retailerIds.forEach((retailerId) => {
      retailers[retailerId].index = index;
      // retailers[retailerId].vehicleType = vehicleType;
    });
  });
  return retailers;
};

export const updateRetailersETA = (picklistArr, retailers, vehicleTypes, dm, deliveryStartTime) => {
  const DST = deliveryStartTime ? parseDST(deliveryStartTime) : 0;

  picklistArr.forEach(({ retailerIds, vehicleTypeId }) => {
    updateETAs(retailerIds, byId(vehicleTypes, vehicleTypeId).avgSpeed);
  });

  function parseDST(dst) {
    const tmp = dst.split(':').map((x) => parseInt(x));
    return (tmp[0] * 60 + tmp[1]) * 60;
  }

  function updateETAs(retailerIds, speed) {
    let totalTravelTime = DST;
    let totalServiceTime = 0;
    let totalWaitingTime = 0;
    let lastDMIndex = 0;
    retailerIds.forEach((rid) => {
      // Time taken to reach the retailer (traveltime + servicetime till now)
      totalTravelTime += getTravelTime(retailers[rid].dmIndex, lastDMIndex, speed);
      retailers[rid].eta = totalTravelTime + totalServiceTime + totalWaitingTime;

      //Calculating waiting time if there
      let waitingTime = 0;
      for (let i = 0; i < retailers[rid].deliveryWindow.length; i++) {
        if (retailers[rid].eta <= retailers[rid].deliveryWindow[i].start * 60) {
          waitingTime = retailers[rid].deliveryWindow[i].start * 60 - retailers[rid].eta;
          break;
        }
        if (
          retailers[rid].eta > retailers[rid].deliveryWindow[i].start * 60 &&
          retailers[rid].eta <= retailers[rid].deliveryWindow[i].end * 60
        ) {
          waitingTime = 0;
          break;
        }
      }
      // const waitingTime = retailers[rid].deliveryWindow.reduce((acc, { start }) => {
      //   if (acc) return acc;
      //   return retailers[rid].eta < start * 60 ? start * 60 - retailers[rid].eta : 0;
      // }, 0);

      // totalWaitingTime += waitingTime < 15 * 60 ? waitingTime : 0;
      // retailers[rid].eta = waitingTime;
      retailers[rid].waitingTime = waitingTime;
      retailers[rid].deliveryStartTime = retailers[rid].eta + retailers[rid].waitingTime;
      totalWaitingTime += waitingTime;

      lastDMIndex = retailers[rid].dmIndex;
      totalServiceTime += retailers[rid].serviceTime;
    });
  }
  function getTravelTime(toDMIndex, fromDMIndex, speed) {
    return dm[fromDMIndex][toDMIndex] / speed;
  }

  return retailers;
};

export const getPicklistsFromPlan = (picklists, retailers, isArray = false) => {
  const newPicklists = isArray ? picklists : Object.values(picklists);
  return arrayToObject(
    newPicklists.map((pl) => {
      return {
        ...pl,
        ...pl.retailerIds.reduce(
          (acc, retailerId) => ({
            volume: acc.volume + retailers[retailerId].volume,
            weight: acc.weight + retailers[retailerId].weight,
            sales: acc.sales + retailers[retailerId].sales,
            serviceTime: acc.serviceTime + retailers[retailerId].serviceTime,
            waitingTime: acc.waitingTime + (retailers[retailerId].waitingTime || 0),
          }),
          {
            volume: 0,
            weight: 0,
            sales: 0,
            serviceTime: 0,
            waitingTime: 0,
          }
        ),
      };
    }),
    'index'
  );
};

export const distSq = ({ lat: lat1, lng: lng1 }, { lat: lat2, lng: lng2 }) =>
  Math.pow(lat2 - lat1, 2) + Math.pow(lng2 - lng1, 2);

export const getLatLng = ({ latitude: lat, longitude: lng }) => ({ lat, lng });

export const findClosestRetailer = (latLng, retailers) => {
  let retailersArray = Object.values(retailers).filter(
    ({ latitude, longitude }) => latitude && longitude
  );
  let dist = 0;
  let closestRetailer = retailersArray[0];
  let shortestDist = distSq(latLng, getLatLng(retailersArray[0]));
  retailersArray.forEach((ret) => {
    dist = distSq(latLng, getLatLng(ret));
    if (dist < shortestDist) {
      closestRetailer = ret;
      shortestDist = dist;
    }
  });
  return { closestRetailer, shortestDist };
};

export const getIcon = (options) => {
  const outerRadius = options.scale,
    innerRadius = options.innerRadius || 0,
    fillColor = options.fillColor || '#ff0000',
    strokeColor = options.strokeColor || '#000000',
    strokeWidth = options.strokeWeight || 0.3;

  const canvas = document.createElement('canvas');
  canvas.width = (outerRadius + 1) * 2;
  canvas.height = (outerRadius + 1) * 2;

  const ctx = canvas.getContext('2d');

  ctx.clearRect(0, 0, canvas.width, canvas.height);

  ctx.fillStyle = fillColor;
  ctx.lineWidth = strokeWidth;
  ctx.strokeStyle = strokeColor;

  ctx.beginPath();
  ctx.arc(outerRadius + 1, outerRadius + 1, outerRadius, 0, 2 * Math.PI, false);
  ctx.arc(outerRadius + 1, outerRadius + 1, innerRadius, 0, 2 * Math.PI, true);
  ctx.closePath();
  ctx.fill();

  if (strokeWidth > 0) {
    ctx.beginPath();
    ctx.arc(outerRadius + 1, outerRadius + 1, outerRadius, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(outerRadius + 1, outerRadius + 1, innerRadius, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.stroke();
  }

  return canvas.toDataURL();
};

export const getRectangle = (options) => {
  var height = options.height,
    width = options.width || height,
    fillColor = options.fillColor || '#ff0000',
    strokeColor = options.strokeColor || '#000000',
    strokeWidth = options.strokeWidth || 0.3;

  var canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;

  var ctx = canvas.getContext('2d');

  ctx.clearRect(0, 0, canvas.width, canvas.height);

  ctx.fillStyle = fillColor;
  ctx.lineWidth = strokeWidth;
  ctx.strokeStyle = strokeColor;

  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.lineTo(width, 0);
  ctx.lineTo(width, height);
  ctx.lineTo(0, height);
  ctx.lineTo(0, 0);
  ctx.closePath();
  ctx.fill();

  if (strokeWidth > 0) {
    ctx.stroke();
  }

  return canvas.toDataURL();
};

export const getStar = (options) => {
  var outerRadius = options.scale,
    innerRadius = options.innerRadius || 0,
    fillColor = options.fillColor || '#ff0000',
    strokeColor = options.strokeColor || '#000000',
    strokeWidth = options.strokeWidth || 0.3,
    spikes = options.spikes || 5;

  var canvas = document.createElement('canvas');
  canvas.width = (outerRadius + 1) * 2;
  canvas.height = (outerRadius + 1) * 2;

  var ctx = canvas.getContext('2d');

  ctx.clearRect(0, 0, canvas.width, canvas.height);

  var rot = (Math.PI / 2) * 3;
  var cx = canvas.width / 2;
  var cy = canvas.height / 2;
  var x = cx;
  var y = cy;
  var step = Math.PI / spikes;

  ctx.beginPath();
  ctx.moveTo(cx, cy - outerRadius);
  for (var i = 0; i < spikes; i++) {
    x = cx + Math.cos(rot) * outerRadius;
    y = cy + Math.sin(rot) * outerRadius;
    ctx.lineTo(x, y);
    rot += step;

    x = cx + Math.cos(rot) * innerRadius;
    y = cy + Math.sin(rot) * innerRadius;
    ctx.lineTo(x, y);
    rot += step;
  }
  ctx.lineTo(cx, cy - outerRadius);
  ctx.closePath();

  ctx.fillStyle = fillColor;
  ctx.fill();

  if (strokeWidth > 0) {
    ctx.lineWidth = strokeWidth;
    ctx.strokeStyle = strokeColor;
    ctx.stroke();
  }

  return canvas.toDataURL();
};

export const mapStyles = [
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.neighborhood',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.business',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
];
