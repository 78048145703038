import React, { useState, useEffect } from 'react';
import ScrollMenu from 'react-horizontal-scroll-menu';
import { useBranchId } from 'utils/customHooks';
import { actionTypes } from 'delivery/planning/planningReducer';
import { useNavigate } from '@reach/router';

export default function PlanMenu({ id, plansList, picklists, dispatch }) {
  const navigate = useNavigate();
  const branchId = useBranchId();
  const [selected, setSelected] = useState(id);
  const selectedPlan = plansList ? plansList.filter((x) => x.planId === Number(id)) : [];
  const selectedBranchId = selectedPlan ? selectedPlan.map((x) => x.branchId)[0] : [];
  const plans = plansList ? plansList.filter((x) => x.branchId === Number(selectedBranchId)) : [];

  let planObject = {
    planId: undefined,
    profileName: ' ',
  };

  const plansInfoTemp = [];

  plans.forEach((item) => {
    let profileName = !item.profileName ? 'Default' : item.profileName;
    planObject = {
      planId: item.planId,
      profileName: profileName,
      createdAt: item.createdAt,
      status: item.status,
    };
    plansInfoTemp.push(planObject);
  });

  const plansInfo = plansInfoTemp.sort((a, b) => Number(a.createdAt) - Number(b.createdAt));

  const MenuItem = ({ text, selected }) => {
    return (
      <div
        className={`menu-item-plan-polishing ${selected ? 'active' : ''}`}
        style={{ margin: '0 10px', padding: '5px 10px', whiteSpace: 'nowrap' }}
      >
        {text}
      </div>
    );
  };

  const Menu = (plansInfo, selected) =>
    plansInfo.map(({ planId, profileName, status }) => (
      <MenuItem
        text={`${planId}/ ${profileName} (${status === 'frozen' ? 'F' : 'UF'})`}
        key={planId}
        selected={selected}
      />
    ));

  const Arrow = ({ text, className }) => {
    return <div className={className}>{text}</div>;
  };

  const ArrowLeft = Arrow({ text: '<' });
  const ArrowRight = Arrow({ text: '>' });

  useEffect(() => {
    dispatch({
      type: actionTypes.UPDATE_SELECTED_PICKLISTS,
      selectedPicklists: picklists.map((n) => n.index),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (selected) {
      navigate(`/dl/plan/${selected}?branchId=${branchId}`);
    }
  }, [selected, branchId, navigate]);

  const onSelect = (key) => {
    setSelected(key);
  };

  return (
    <ScrollMenu
      data={Menu(plansInfo, selected)}
      arrowLeft={ArrowLeft}
      arrowRight={ArrowRight}
      selected={selected}
      onSelect={onSelect}
    />
  );
}
